<template>
  <div v-if="model">
    <div class="row justify-content-center">
      <div class="form-group col-4">
        <label for>Blood Group</label>
        <fg-input readonly type="text" placeholder="Nil" v-model="model.blood_group"></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Ref Name</label>
        <fg-input
          readonly
          type="text"
          name="ref_name"
          placeholder="Nil"
          v-model="model.ref_name"
          :error="getError('ref_name')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Ref Address</label>
        <fg-input
          readonly
          type="text"
          name="ref_address"
          placeholder="Nil"
          v-model="model.ref_address"
          :error="getError('ref_address')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Date Joined</label>
        <el-date-picker
          readonly
          name="doj"
          v-model="model.doj"
          type="date"
          placeholder="Pick a Date"
        ></el-date-picker>
      </div>

      <div class="form-group col-4">
        <label for>Languages</label>
        <el-select
          readonly
          v-model="model.languages"
          multiple
          filterable
          allow-create
          default-first-option
          placeholder="Choose tags for your article"
        >
          <el-option
            v-for="item in local.languages"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="form-group col-4">
        <label for>Designation</label>
        <fg-input
          readonly
          type="text"
          name="ref_address"
          placeholder="Nil"
          v-model="model.designation"
          :error="getError('ref_address')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Bio</label>
        <fg-input
          readonly
          type="text"
          name="bio"
          placeholder="Nil"
          v-model="model.bio"
          :error="getError('bio')"
        ></fg-input>
      </div>

      <div class="form-group col-4">
        <label for>Education</label>
        <fg-input
          readonly
          type="text"
          name="education_level"
          placeholder="Nil"
          v-model="model.education_level"
          :error="getError('education_level')"
        ></fg-input>
      </div>
    </div>
  </div>
</template>
<script>
import { Upload, DatePicker, Select, Option } from "element-ui";
import { mapGetters } from "vuex";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Upload.name]: Upload,
    [Select.name]: Select,
    [Option.name]: Option
  },
  computed: {
    ...mapGetters({
      blood_groups: "getBloodGroups"
    })
  },
  props: ["model"],
  data() {
    return {
      local: {
        languages: [
          { value: "English", label: "English" },
          { value: "Hindi", label: "Hindi" },
          { value: "Kannada", label: "Kannada" },
          { value: "Tamil", label: "Tamil" },
          { value: "Malayalam", label: "Malayalam" }
        ],
        designations: [
          { value: "Accountant", label: "Accountant" },
          { value: "Front Desk", label: "Front Desk" },
          { value: "Prescription Writer", label: "Prescription Writer" }
        ]
      },
      model: {
        blood_group: "",
        ref_name: "",
        ref_address: "",
        doj: "",
        designation: "",
        bio: "",
        education_level: "",
        password: "",
        role: "",
        languages: ""
      },
      modelValidations: {
        blood_group: {
          required: false
        },
        ref_name: {
          required: false
        },
        ref_address: {
          required: false
        },
        doj: {
          required: false
        },
        designation: {
          required: false
        },
        education_level: {
          required: false
        },
        password: {
          required: false,
          min: 6
        }
      }
    };
  },
  methods: {
    handlePreview(file) {
      this.model.imageUrl = URL.createObjectURL(file.raw);
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    }
  }
};
</script>
<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>
